import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 初始化CSS
import "normalize.css";

// 导入重置CSS文件
import "./style/css/global.css";
import "./style/reset.scss";

// 导入全局组件
import "@/components";

// 导入element-ui组件
import { Cascader, DatePicker, InputNumber, Message, Radio, Select, Switch } from 'element-ui';

Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.prototype.$message = Message

Vue.config.productionTip = false;

// 设置全局路由前置守卫 验证用户是否登录
router.beforeEach(async (to, from, next) => {
  // const token = store.state.login.token
  const token = store.getters.token;
  console.log(token);

  // // 判断是否有token
  if (token) {
    next();
    // 有token 验证是否有用户信息
    // if (!store.getters.username) {
    //   try {
    //     // 重新拉取用户信息【派发action】
    //     await store.dispatch("login/getUserInfo");
    //     next();
    //   } catch (error) {
    //     // token错误或者过期
    //     // 清理token
    //     store.commit("login/RESET_STATE");
    //     // 跳转到登录
    //     next("/");
    //   }
    // } else {
    //   next();
    // }
  } else {
    if (to.meta.isRole) {
      next("/");
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
