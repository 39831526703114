<template>
  <!-- 头部 -->
  <div>
    <div class="topbar">
      <div class="wrap">
        <div class="left">
          <ul>
            <li>欢迎使用牙艺云商城！</li>
            <li v-if="token">
              <span style="margin: 0 15px">|</span>{{ companyinfo.title }}
            </li>
          </ul>
        </div>

        <div class="right">
          <ul>
            <li style="margin-right: 30px" @click="goHome">
              <img src="@/assets/images/ic_home.png" alt />
              首页
            </li>
            <li v-if="!loginInfo">
              <span
                @click="
                  chanIsShowLoginModal({
                    isShowLoginModal: true,
                    isShowForm: 1,
                  })
                "
                >登录</span
              >
              <span style="margin: 0 12px 0 8px">|</span>
              <span
                @click="
                  chanIsShowLoginModal({
                    isShowLoginModal: true,
                    isShowForm: 2,
                  })
                "
                >注册</span
              >
            </li>
            <li v-else>
              <div v-if="loginInfo" @click="goOrder" class="account">
                <img class="avatar" :src="loginInfo.avatar" />
                <span class="nickName">{{ loginInfo.nickname }}</span>
              </div>
              <div
                v-if="!companyinfo.user"
                @click="logout"
                style="margin-left: 8px"
              >
                退出登录
              </div>
              <div class="department" v-if="companyinfo.user">
                {{ companyinfo.user.sector }}
                <span></span>

                <div class="logOut_area">
                  <div
                    style="display: flex; align-items: center"
                    v-if="loginInfo"
                  >
                    <img
                      style="border-radius: 50%"
                      :src="loginInfo.avatar"
                      alt
                    />
                    <div style="font-size: 14px">{{ loginInfo.nickname }}</div>
                  </div>
                  <div class="logOut_btn" @click="logout">退出登录</div>
                </div>
              </div>
            </li>
            <li class="li_style">|</li>
            <li @click="goOrder">个人中心</li>
            <li class="li_style">|</li>
            <li @click="purchaseBill">采购账单</li>
            <li class="li_style">|</li>
            <li @click="kaipiao">申请开票</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="logo">
        <router-link to="/home">
          <img src="@/assets/images/favicon.png" alt />
        </router-link>
      </div>

      <div class="search">
        <input
          class="inputSearch"
          type="text"
          placeholder="搜索：贺利氏超硬石膏"
          autocomplete="off"
          v-model="kw"
          @keyup.enter="getSearch"
        />
        <div class="search_btn" @click="getSearch">
          <img width="22" src="@/assets/images/ic_search.png" alt />
        </div>
      </div>

      <div
        class="shopping_cart"
        :class="countNum > 0 ? 'active' : ''"
        @click="goCart"
      >
        <img src="@/assets/images/ic_gouwuche.png" alt />
        购物车({{ countNum || 0 }})
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "AppHeader",
  inject: ["reload"],
  data() {
    return {
      isLogined: false,
      kw: "",
      countNum: this.count,
      loginInfo: JSON.parse(localStorage.getItem("loginInfo")),
      // companyinfo: {}, // 用户企业信息
    };
  },

  computed: {
    ...mapState("login", ["userInfo"]),
    ...mapState("header", ["companyinfo", "count", "configList"]),
    ...mapGetters(["username", "token"]),
  },

  created() {
    this.indexConfig();
    this.getUserInfo();
    // this.getCartCount();
    this.getUserCompanyInfo();
  },

  watch: {
    count(val) {
      this.countNum = val;
    },
  },

  methods: {
    ...mapMutations({
      chanIsShowLoginModal: "showLoginModal/chanIsShowLoginModal",
    }),

    async indexConfig() {
      await this.$store.dispatch("header/indexConfig");
    },

    async getUserCompanyInfo() {
      if (this.loginInfo) {
        if (this.loginInfo.company_id) {
          await this.$store.dispatch("header/getUserCompanyInfo", {
            company_id: this.loginInfo.company_id,
          });

          this.getCartCount();
        }
      }
    },

    async getCartCount() {
      if (this.loginInfo) {
        if (this.loginInfo.company_id) {
          await this.$store.dispatch("header/getCartCount", {
            company_id: this.loginInfo.company_id,
          });
        }
      }
    },

    async getUserInfo() {
      if (this.loginInfo) {
        await this.$store.dispatch("header/getUserInfo");
      }
    },

    goHome() {
      this.$router.push("/home");
    },

    getSearch() {
      // if (this.token) {
      //   if (this.loginInfo.company_id) {
      //     this.$router.push({
      //       name: "search",
      //       params: { keyword: this.kw || undefined },
      //       query: this.$route.query,
      //     });
      //   } else {
      //     this.$router.push("/infoRenzheng");
      //   }
      // } else {
      //   this.chanIsShowLoginModal({
      //     isShowLoginModal: true,
      //     isShowForm: 1,
      //   });
      // }

      // this.$router.push('/search')
      // 跳转到搜索页面 并将关键字 通过动态路由传入  匹配结果为空时需要传递 undefined
      this.$router.push({
        name: "search",
        params: { keyword: this.kw || undefined },
        query: this.$route.query,
      });
      // this.kw = ''
    },

    // 购物车
    goCart() {
      if (this.token) {
        if (this.loginInfo.company_id) {
          this.$router.push("/cart");
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 个人中心
    goOrder() {
      if (this.token) {
        if (this.loginInfo.company_id) {
          this.$router.push("/order");
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 采购账单
    purchaseBill() {
      if (this.token) {
        if (this.loginInfo.company_id) {
          this.$router.push("/bill");
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 申请开票
    kaipiao() {
      if (this.token) {
        if (this.loginInfo.company_id) {
          this.$router.push({
            name: "order",
            query: { isShow: 1, currentIndex: 3 },
          });
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 退出登录
    async logout() {
      // 回到首页
      this.$router.push("/");
      await this.$store.dispatch("login/logout");
      // 回到首页
      this.$router.push("/");
      // 刷新页面
      window.location.reload();
      // this.reload();
      this.countNum = 0;
    },
  },

  // mounted() {
  //   // 订阅 清除关键词事件
  //   this.$bus.$on('clearKw', () => {
  //     this.kw = ''
  //   })
  // },

  // beforeDestroy() {
  //   // 取消订阅 清除关键词事件
  //   this.$bus.$off('clearKw')
  // }
};
</script>

<style lang="scss" scoped>
.topbar {
  height: 40px;
  background: #fafafa;

  font-size: 12px;
  font-weight: 400;
  color: #888888;
  line-height: 40px;

  .wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left ul {
      display: flex;
    }

    .right {
      position: relative;
    }

    .right ul {
      display: flex;

      li {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 13px;
          height: 14px;
          margin-right: 8px;
        }

        .avatar {
          width: 25px;
          height: 25px;
          vertical-align: middle;
          border-radius: 50%;
          margin-right: 10px;
        }

        .nickName {
          font-size: 14px;
        }

        .department {
          position: relative;
          display: flex;
          align-items: center;
          font-size: 11px;
          margin-left: 5px;

          &:hover .logOut_area {
            display: block;
          }

          span {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-right: 1px solid #888888;
            border-bottom: 1px solid #888888;
            transform: rotate(45deg);
            margin: 0 7px 0 5px;
          }

          .logOut_area {
            position: absolute;
            top: 39px;
            left: -95px;
            width: 222px;
            height: 127px;
            background: #ffffff;
            // border: 1px solid red;
            padding: 20px 0 0 10px;
            display: none;

            img {
              width: 38px;
              height: 38px;
              margin-right: 10px;
            }

            .logOut_btn {
              width: 128px;
              height: 26px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              background: #00a4a0;
              border-radius: 3px;
              text-align: center;
              line-height: 26px;
              margin: 28px 0 0 30px;
              cursor: pointer;
            }
          }
        }

        &.li_style {
          margin: 0 12px 0 8px;
        }
      }
    }
  }
}

.wrap {
  display: flex;
  height: 74px;
  background: #ffffff;

  .logo {
    width: 272px;
    height: 59px;
    margin: 8px 20px 0 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .search {
    display: flex;
    margin-top: 17px;

    .inputSearch {
      width: 768px;
      height: 38px;
      border-radius: 4px 0 0 4px;
      border: 1px solid #00a4a0;
      background: #fff;
      text-indent: 12px;
    }

    .search_btn {
      width: 52px;
      height: 40px;
      background: #00a4a0;
      border-radius: 0px 4px 4px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .shopping_cart {
    width: 134px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    margin: 17px 0 40px 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      margin-right: 10px;
    }

    &.active {
      color: #00a4a0;
      border: 1px solid #00a4a0;
    }
  }
}
</style>
