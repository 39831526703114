<template>
  <!-- 商品分类导航 -->
  <div class="type-nav">
    <div class="container">
      <div class="goods_nav">
        <ul>
          <li
            :class="$route.path == '/home' ? 'active' : ''"
            @click="$router.push('/home')"
          >
            首页
          </li>
          <li :class="$route.path == '/goods' ? 'active' : ''" @click="goGoods">
            全部商品
          </li>
          <li
            v-for="(item, index) in menuList"
            :key="index"
            @click="goMenuList(item.jump_url_type, item.jump_url)"
          >
            {{ item.name }}
          </li>
          <!-- <li
            :class="$route.path == '/coupon' ? 'active' : ''"
            @click="goCoupon"
          >
            优惠券
          </li> -->
        </ul>
      </div>

      <div class="tel">
        <img
          width="18"
          src="@/assets/images/ic_iphone.png"
          alt=""
        />客服电话：{{ configList.service_mobile }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "TypeNav",
  data() {
    return {
      loginInfo: JSON.parse(localStorage.getItem("loginInfo")),
    };
  },
  computed: {
    ...mapState("header", ["configList"]),
    ...mapState("login", ["userInfo"]),
    ...mapState("home", ["menuList"]),
    ...mapGetters(["username", "token"]),
  },

  mounted() {},

  methods: {
    ...mapMutations({
      chanIsShowLoginModal: "showLoginModal/chanIsShowLoginModal",
    }),

    goGoods() {
      if (this.token) {
        if (this.loginInfo.company_id) {
          this.$router.push("/goods");
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    goMenuList(jump_url_type, jump_url) {
      if (this.token) {
        if (this.loginInfo.company_id) {
          if (jump_url_type == "inside") {
            this.$router.push({
              name: "coupon",
              query: { company_id: this.loginInfo.company_id },
            });
          } else {
            if (jump_url == "") {
              this.$message.warning("链接失效了,请联系客服");
            } else {
              window.location.href = jump_url;
            }
          }
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    goCoupon() {
      if (this.token) {
        if (this.loginInfo.company_id) {
          this.$router.push("/coupon");
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.type-nav {
  height: 62px;
  background: #fff;
  line-height: 62px;

  .container {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .goods_nav ul {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-left: 240px;

      li {
        margin-right: 40px;
        cursor: pointer;

        &.active {
          color: #00a4a0;
          font-weight: bold;
        }
      }
    }

    .tel {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #00a4a0;
      margin-left: 35px;

      img {
        margin-right: 10px;
      }
    }
  }
}
</style>
