// 用户模块
import { CodeLoginAPI, getUserInfo, logout, passwordLoginAPI, RegisterLoginAPI } from '@/api'
import { GET_TOKEN, REMOVE_TOKEN, SET_TOKEN } from '@/utils/loginToken'

export default {
  namespaced: true,
  state: {
    token: GET_TOKEN() || '',
    userInfo: {},
    loginInfo: {} || localStorage.getItem('loginInfo'),
    // companyinfo: {}
  },

  mutations: {
    SET_TOKEN(state, data) {
      state.token = data
    },

    SET_USER_INFO(state, info) {
      state.userInfo = info
    },

    SET_LOGIN_INFO(state, login) {
      state.loginInfo = login
    },

    // SET_COMPANY_INFO(state, company) {
    //   state.companyInfo = company
    // },

    RESET_STATE(state) {
      state.token = ''
      state.userInfo = {}
      REMOVE_TOKEN()
      localStorage.removeItem('loginInfo')
    },

  },

  actions: {
    // // 获取验证码
    // async getCode({ commit }, phone) {
    //   const res = await SendSMSAPI(phone)
    //   console.log(res)
    // },

    // 用户密码登录
    async pwdLogin({ commit, dispatch }, data) {
      // 1. 发起请求进行登录
      const res = await passwordLoginAPI(data)
      console.log(res, '用户密码登录');

      // 2. 保存token到state中
      commit('SET_TOKEN', res.userinfo.token)

      // commit('SET_LOGIN_INFO', JSON.stringify(res.userinfo))

      // 3. 保存token到本地（持久化）
      SET_TOKEN(res.userinfo.token)
      // SET_LOGIN_INFO(JSON.stringify(res.userinfo))

      localStorage.setItem('loginInfo', JSON.stringify(res.userinfo))

      // 4. 派发action 获取用户信息
      dispatch('getUserInfo')
    },

    // 获取用户信息
    async getUserInfo({ commit }) {
      // 1. 发请求获取用户信息
      const data = await getUserInfo()
      console.log(data, '获取用户信息');
      // 2. 将用户信息保存
      commit('SET_USER_INFO', data.userinfo)
    },

    // 用户验证码登录
    async codeLogin({ commit, dispatch }, data) {
      // 1. 发起请求进行登录
      const res = await CodeLoginAPI(data)
      console.log(res, '用户验证码登录');

      // 2. 保存token到state中
      commit('SET_TOKEN', res.userinfo.token)

      // 3. 保存token到本地（持久化）
      SET_TOKEN(res.userinfo.token)
      localStorage.setItem('loginInfo', JSON.stringify(res.userinfo))

      // 4. 派发action 获取用户信息
      dispatch('getUserInfo')
    },

    // 注册用户
    async register({ commit, dispatch }, data) {
      const res = await RegisterLoginAPI(data)
      console.log(res, '注册用户');
      // 2. 保存token到state中
      commit('SET_TOKEN', res.userinfo.token)
      // 3. 保存token到本地（持久化）
      SET_TOKEN(res.userinfo.token)
      localStorage.setItem('loginInfo', JSON.stringify(res.userinfo))

      // 4. 派发action 获取用户信息
      dispatch('getUserInfo')
    },

    // 重置密码
    // async resetPwd({ commit }, data) {
    //   await ResetpwdLoginAPI(data)
    // },

    // 用户退出登录
    async logout({ commit }) {
      // 1. 发起请求退出登录
      const res = await logout()
      console.log(res);
      // 2. 清理本地状态
      commit('RESET_STATE')
    },

  },
  getters: {}
}
