import Vue from "vue";
import VueRouter from "vue-router";
// 导入布局组件
import Layout from "@/layout";
import H5 from "@/layout/H5";

// 自己编写跳转路由方法
// 保存VueRouter的push方法
const vPush = VueRouter.prototype.push;
// 重写vue路由push方法 将push方法默认携带函数
VueRouter.prototype.push = function (location, cb1, cb2) {
  if (cb1 || cb2) return vPush.call(this, location, cb1, cb2);
  return vPush.call(this, location, () => { });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    // 路由重定向到主页
    redirect: "/home",
    children: [
      // // 登录
      // { path: '/login/:status?', component: () => import('@/views/login'), name: 'login', props: true },
      // 主页
      { path: "/home", component: () => import("@/views/home") },
      // 全部商品
      {
        path: "/goods",
        component: () => import("@/views/goods"),
      },
      // 优惠券
      {
        path: "/coupon",
        component: () => import("@/views/coupon"),
        name: "coupon",
      },
      // 搜索页  /search/:kw? 【? 可传空 0 -> 1个】
      {
        path: "/search/:keyword?",
        component: () => import("@/views/search"),
        name: "search",
      },
      // 商品详情页  【通过设置props:true 将路由参数解析到组件中的props对象中】
      {
        path: "/detail/:goodsId",
        component: () => import("@/views/detail"),
        name: "detail",
        props: true,
      },
      // 商品详情页  【通过设置props:true 将路由参数解析到组件中的props对象中】
      // {
      //   path: "/H5detail/:goodsId",
      //   component: () => import("@/views/H5detail"),
      //   name: "H5detail",
      //   props: true,
      // },
      // 购物车页
      {
        path: "/cart",
        component: () => import("@/views/cart"),
        name: "cart",
      },
      // 订单页面
      {
        path: "/order/:isShow?",
        component: () => import("@/views/order"),
        name: "order",
      },
      // 订单详情
      {
        path: "/orderDetail/:orderId?",
        component: () => import("@/views/orderDetail"),
        name: "orderDetail",
      },
      // 售后详情
      {
        path: "/refundDetail/:orderId?",
        component: () => import("@/views/refundDetail"),
        name: "refundDetail",
      },
      // 申请售后
      {
        path: "/applySales/:orderId?",
        component: () => import("@/views/applySales"),
        name: "applySales",
      },
      // 信息认证
      {
        path: "/infoRenzheng",
        component: () => import("@/views/infoRenzheng"),
        name: "infoRenzheng",
      },
      // 确认订单
      {
        path: "/confirmorder/:price?",
        component: () => import("@/views/confirmorder"),
        name: "confirmorder",
      },
      // 支付方式
      {
        path: "/payment",
        component: () => import("@/views/payment"),
        name: "payment",
      },
      // 订单支付成功页
      {
        path: '/payok',
        component: () => import('@/views/payment/pay-ok'),
        name: 'payok'
      },
      // 采购账单
      {
        path: "/bill",
        component: () => import("@/views/bill"),
        name: "bill",
      },
      // 我的发票
      {
        path: "/invoice",
        component: () => import("@/views/invoice"),
        name: "invoice",
      },
      // 更多功能
      {
        path: "/PrivacyPolicy",
        component: () => import("@/views/PrivacyPolicy"),
        name: "PrivacyPolicy",
      },
    ],
  },
  {
    path: '/H5detail',
    component: H5,
    children: [
      {
        path: "/H5detail/:goodsId",
        component: () => import("@/views/H5detail"),
        name: "H5detail",
        props: true,
      },
      {
        path: "/agreement",
        component: () => import("@/views/agreement"),
        name: "agreement",
      },
    ]
  }
];

const router = new VueRouter({
  routes,
  Layout,
  // 滚动行为：设置页面滚动条位置
  // behavior: 'smooth' 设置滚动动画
  scrollBehavior: (to, from, savedPosition) => {
    // 如果是回退行为 则不回到顶部
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

export default router;
