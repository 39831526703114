// 导入API接口函数
import { getCartCount, getUserCompanyInfo, getUserInfo, indexConfig } from '@/api';

// 首页模块
export default {
  namespaced: true, // 命名空间
  state: {
    userInfo: {},
    companyinfo: {},
    configList: {},
    count: 0
  },

  mutations: {
    SET_USER_INFO(state, info) {
      state.userInfo = info
    },

    SET_COMPANY_INFO(state, payload) {
      state.companyinfo = payload
    },

    SET_CONFIG_LIST(state, config) {
      state.configList = config
    },

    SET_COUNT(state, countnum) {
      state.count = countnum;
    },
  },

  actions: {
    // 获取用户信息
    async getUserInfo({ commit }) {
      // 1. 发请求获取用户信息
      const data = await getUserInfo()
      console.log(data, '获取用户信息');
      // 2. 将用户信息保存
      commit('SET_USER_INFO', data.userinfo)
      localStorage.setItem('loginInfo', JSON.stringify(data.userinfo))
    },

    // 获取用户企业信息
    async getUserCompanyInfo({ commit }, query) {
      const res = await getUserCompanyInfo(query);
      console.log(res, '获取用户企业信息');
      commit('SET_COMPANY_INFO', res)
    },

    // 基本配置信息
    async indexConfig({ commit }) {
      const res = await indexConfig();
      // console.log(res, '基本配置信息');
      commit('SET_CONFIG_LIST', res)
    },

    // 购物车数据条数
    async getCartCount({ commit }, data) {
      let res = await getCartCount(data);
      console.log(res, '购物车数据条数');
      commit("SET_COUNT", res.count);
    },
  },

  getters: {

  }
}