<template>
  <div class="detail-view">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "H5",

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
