// 导入API接口函数
import { getCategoryList, HomeIndexList } from "@/api";

// 首页模块
export default {
  namespaced: true, // 命名空间
  state: {
    classifyList: [], // 分类
    bannerList: [], // 轮播图
    menuList: [], // 菜单栏
    operationList: [], // 运营栏
    // goodList: [], // 商品列表
  },

  mutations: {
    SET_CLASSIFY_LIST(state, payload) {
      state.classifyList = payload;
    },
    SET_BANNER_LIST(state, banner) {
      state.bannerList = banner;
    },
    SET_MENU_LIST(state, menu) {
      state.menuList = menu;
    },

    SET_OPERATION_LIST(state, operation) {
      state.operationList = operation;
    },
    // SET_GOOD_LIST(state, good) {
    //   state.goodList = good
    // },


    SET_USER_INFO(state, info) {
      state.userInfo = info
    },
  },

  actions: {
    // 首页运营区数据
    async HomeIndexList({ commit }) {
      let res = await HomeIndexList();
      console.log(res, '首页运营区数据');
      commit("SET_BANNER_LIST", res.banner);
      commit("SET_MENU_LIST", res.menu);
      commit("SET_OPERATION_LIST", res.operation);
    },

    // 发起请求，获取分类数据
    async getCategoryList({ commit }) {
      let res = await getCategoryList();
      commit("SET_CLASSIFY_LIST", res);
    },

    // 首页推荐商品
    // async HomeGoodList({ commit }) {
    //   let res = await HomeGoodList({
    //     cate_id: 0,
    //     is_index: 0,
    //     sort_field: 'none',
    //     sort: 'asc',
    //     search: '',
    //     page: 1,
    //     limit: 10,
    //   });
    //   commit('SET_GOOD_LIST', res.data)
    // },
  },
};
