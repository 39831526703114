<template>
  <!-- 底部 -->
  <div class="foot">
    <div class="wrap">
      <div class="foot_top">
        <div style="text-align: center">
          <img class="cartImg" src="@/assets/images/bot1.png" alt="" />
          <div class="freeShipping">品类齐全</div>
          <!-- <div class="wholeCountry">全国支持免费配送</div> -->
        </div>
        <div style="text-align: center">
          <img class="cartImg" src="@/assets/images/bot2.png" alt="" />
          <div class="freeShipping">售后保障</div>
          <!-- <div class="wholeCountry">全国支持免费配送</div> -->
        </div>
        <div style="text-align: center">
          <img class="cartImg" src="@/assets/images/bot3.png" alt="" />
          <div class="freeShipping">交易安全</div>
          <!-- <div class="wholeCountry">全国支持免费配送</div> -->
        </div>
        <div style="text-align: center">
          <img class="cartImg" src="@/assets/images/bot4.png" alt="" />
          <div class="freeShipping">采购账单</div>
          <!-- <div class="wholeCountry">全国支持免费配送</div> -->
        </div>
      </div>
      <div class="line"></div>
      <div class="foot_bottom">
        <div>
          <div class="titleName">新手指南</div>
          <div class="name" @click="goPay(15)">了解我们</div>
          <div class="name" @click="goPay(16)">免费注册</div>
          <div class="name" @click="goPay(17)">企业认证</div>
          <div class="name" @click="goPay(18)">忘记密码</div>
        </div>
        <div>
          <div class="titleName">在线购物</div>
          <div class="name" @click="goPay(19)">购物流程</div>
          <div class="name" @click="goPay(20)">发票开具</div>
          <div class="name" @click="goPay(21)">支付方式</div>
          <div class="name" @click="goPay(22)">确认收货</div>
        </div>
        <div>
          <div class="titleName">配送方式</div>
          <div class="name" @click="goPay(23)">配送运费</div>
          <div class="name"></div>
          <div class="name"></div>
          <div class="name"></div>
        </div>
        <div>
          <div class="titleName">售后服务</div>
          <div class="name" @click="goPay(24)">售后流程</div>
          <div class="name" @click="goPay(25)">售后保障</div>
          <div class="name"></div>
          <div class="name"></div>
        </div>
      </div>
    </div>

    <div class="maxBottom">
      <div class="Filing_information">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >浙ICP备2022028983号
        </a>
        <span>|</span>
        <a
          href="https://yyy.hzgdwl.com/uploads/20221226/1254e7ee6f8e78dc26172f94bbdd890d.png"
          target="_blank"
          >互联网药品信息服务资格证书（浙）-非经营性-2022-0122</a
        >
        <span>|</span>
        <a
          href="https://yyy.hzgdwl.com/uploads/20221226/c6c175336e6a5f65e5fdf8d0050ee5ca.png"
          target="_blank"
          >第二类医疗器械经营备案凭证：浙杭药监械经营备20222934号</a
        >
        <!-- <span>|</span>
        <a href="javascript:" target="_blank">公安备案</a>
        <span>|</span>
        <a href="javascript:" target="_blank">增值电信</a> -->
      </div>
      <div>Copyright©2022杭州牙艺云科技有限公司 版权所有</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AppFooter",
  data() {
    return {
      loginInfo: JSON.parse(localStorage.getItem("loginInfo")),
    };
  },

  computed: {
    ...mapGetters(["username", "token"]),
  },

  methods: {
    ...mapMutations({
      chanIsShowLoginModal: "showLoginModal/chanIsShowLoginModal",
    }),

    // 免费注册
    goReg() {
      if (this.token) {
        if (this.loginInfo) {
          this.chanIsShowLoginModal({
            isShowLoginModal: true,
            isShowForm: 2,
          });
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 2,
        });
      }
    },

    // 企业认证
    goRenzhwng() {
      if (this.token) {
        if (this.loginInfo) {
          this.$router.push({
            name: "order",
            query: { isShow: 7 },
          });
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 忘记密码
    goForget() {
      console.log(this.token);
      console.log(this.loginInfo);
      if (this.token) {
        if (this.loginInfo) {
          this.chanIsShowLoginModal({
            isShowLoginModal: true,
            isShowForm: 1,
          });
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 发票开具
    goInvoice() {
      if (this.token) {
        if (this.loginInfo) {
          this.$router.push({
            name: "order",
            query: { isShow: 4 },
          });
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    // 确认收货
    goShouhuo() {
      if (this.token) {
        if (this.loginInfo) {
          this.$router.push({
            name: "order",
            query: { isShow: 1 },
          });
        } else {
          this.$router.push("/infoRenzheng");
        }
      } else {
        this.chanIsShowLoginModal({
          isShowLoginModal: true,
          isShowForm: 1,
        });
      }
    },

    goPay(num) {
      this.$router.push({
        name: "PrivacyPolicy",
        query: { isShow: num },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.foot {
  .wrap {
    height: 460px;
    padding-top: 56px;
  }

  .foot_top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    // margin-top: 56px;

    .cartImg {
      width: 40px;
      height: 40px;
    }

    .freeShipping {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 6px 0 9px;
    }

    .wholeCountry {
      font-size: 12px;
      font-weight: 400;
      color: #b0b0b0;
    }
  }

  .line {
    width: 1300px;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 70px auto 40px;
  }

  .foot_bottom {
    display: flex;
    justify-content: space-around;
    // align-items: center;
    font-weight: 400;
    color: #666666;

    .titleName {
      font-size: 14px;
      margin-bottom: 24px;
    }

    .name {
      font-size: 12px;
      margin-bottom: 12px;
      cursor: pointer;
    }
  }

  .maxBottom {
    height: 54px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    background: #fafafa;
    text-align: center;
    line-height: 24px;

    .Filing_information {
      text-align: center;

      a {
        text-decoration: none;
      }

      a:hover {
        color: #00a4a0;
      }

      span {
        margin: 0 10px;
      }
    }
  }
}
</style>
