// 所有请求接口的集合
import { GET_TOKEN } from '@/utils/loginToken';
import request from "@/utils/request";


// 发送短信验证码请求
export const SendSMSAPI = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "sms/send",
    data,
  });
};

// 密码登录
export const passwordLoginAPI = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "user/login",
    data,
  });
};

// 验证码登录
export const CodeLoginAPI = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "user/mobilelogin",
    data,
  });
};

// 重置密码
export const ResetpwdLoginAPI = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "user/resetpwd",
    data,
  });
};

// 注册
export const RegisterLoginAPI = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "user/register",
    data,
  });
};

// 退出登录
export const logout = () => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "user/logout",
    headers: {
      token: GET_TOKEN(),
    },
  });
};

// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: "get",
    url: "user/userInfo",
    headers: {
      token: GET_TOKEN(),
    },
  });
};

// 获取用户企业信息
export const getUserCompanyInfo = (data) => {
  return request({
    method: "POST",
    url: "company/getUserCompanyInfo",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 获取 分类列表数据
export const getCategoryList = () => {
  return request({
    method: "get",
    url: "category/getCategoryList",
  });
};

// 首页运营区数据(轮播图,菜单栏,运营位)
export const HomeIndexList = () => {
  return request({
    method: "get",
    url: "index/index",
    data: {
      jump_type: "pc",
    },
  });
};

// 首页商品列表
export const HomeGoodList = (data) => {
  return request({
    method: "POST",
    url: "goods/getPageList",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 商品详情
export const getGoodsDetail = (data) => {
  return request({
    method: "get",
    url: "goods/getDetail",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 地址信息列表
export const getAddressPageList = (data) => {
  return request({
    method: "POST",
    url: "user_address/getAddressPageList",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 获取购物车列表
export const getCartList = (data) => {
  return request({
    method: "get",
    url: "cart/getCartPageList",
    headers: {
      token: GET_TOKEN(),
    },
    data
  });
};

// 添加商品到购物车
export const addCart = (data) => {
  return request({
    method: "POST",
    url: "cart/addCart",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 购物车商品数量的修改
export const valChange = (data) => {
  return request({
    method: "POST",
    url: "cart/editCartGoodsNum",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 购物车商品的删除
export const delCartGoods = (data) => {
  return request({
    method: "POST",
    url: "cart/delCartGoods",
    headers: {
      token: GET_TOKEN(),
    },
    data,
  });
};

// 购物车数据条数
export const getCartCount = (data) => {
  return request({
    method: "get",
    url: "cart/getCartCount",
    headers: {
      token: GET_TOKEN(),
    },
    data
  });
};

// 订单状态角标数量
export const getOrderStatusCount = (data) => {
  return request({
    method: "get",
    url: "order/getOrderStatusCount",
    headers: {
      token: GET_TOKEN(),
    },
    data
  });
};

// 基本配置信息
export const indexConfig = () => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "get",
    url: "index/config",
  });
};


// 确认订单(单商品)
export const goodsConfirmOrder = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "order/goodsConfirmOrder",
    headerss: {
      token: GET_TOKEN(),
      "content-type": "application/json",
    },
    data
  });
};

// 确认订单(购物车商品)
export const cartConfirmOrder = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "order/cartConfirmOrder",
    headerss: {
      token: GET_TOKEN(),
      "content-type": "application/json",
    },
    data
  });
};

// 提交订单（创建）
export const placeOrder = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "post",
    url: "order/placeOrder",
    data
  });
};

// 优惠券中心
export const getCouponPageList = (data) => {
  return request({
    method: "post",
    url: "Coupon/getCouponPageList",
    data
  });
};

// 领取优惠券
export const drawCoupon = (data) => {
  return request({
    method: "post",
    url: "Coupon/drawCoupon",
    data
  });
};