<template>
  <div class="dialog" v-if="isLogisticsDialog">
    <!-- 对话框 -->
    <div class="dialog-view">
      <!-- 头部 -->
      <div class="header">
        <h3 class="title">{{ title }}</h3>
        <img class="del_icon" src="@/assets/images/del_icon.png" alt="" @click="$emit('hidden')" />
      </div>
      <!-- 内容 -->
      <div class="content">
        <slot></slot>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <!-- 插槽默认值 -->
        <slot name="footer">
          <button class="ok" @click="$emit('ok')">{{ okText }}</button>
          <button class="cancel" @click="$emit('cancel')">{{ cancelText }}</button>
        </slot>
      </div>
    </div>
    <!-- 对话框遮罩 -->
    <div class="dialog-mask"></div>
  </div>
</template>

<script>
export default {
  name: 'logisticsDialog',
  props: {
    // 是否显示
    isLogisticsDialog: {
      type: Boolean,
      default: false
    },
    // 标题
    title: String,
    // 取消按钮文字内容
    cancelText: {
      type: String,
      default: '取消'
    },
    // 确定按钮文字内容
    okText: {
      type: String,
      default: '确定'
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-view {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 450px;
  // height: 212px;
  background: #FFF;
  z-index: 9999;

  .header {
    height: 40px;
    background: #F1F3F5;
    padding: 0 15px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      font-size: 20px;
      font-weight: 400;
    }

    .del_icon {
      width: 14px;
      cursor: pointer;
    }
  }

  .content {
    margin: 30px 50px 40px;
    text-align: center;
  }

  .footer {
    margin-bottom: 40px;
    text-align: center;

    button {
      display: inline-block;
      white-space: nowrap;
      font-size: 13px;
      font-weight: 400;
      color: #606266;
      border-radius: 3px;
      background: #fff;
      border: 1px solid #dcdfe6;
      text-align: center;
      line-height: 1;
      box-sizing: border-box;
      margin: 0;
      padding: 8px 20px;
      transition: .1s;
      cursor: pointer;


      &.ok {
        color: #fff;
        margin-right: 20px;
        background: #00A4A0;
        border-color: #00A4A0;
      }
    }
  }
}

.dialog-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}
</style>
