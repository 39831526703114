<template>
  <div class="layout-view">
    <AppHeader />
    <router-view></router-view>
    <Login v-show="isShowLoginModal"></Login>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";
import Login from "./components/Login";

import { mapState } from "vuex";
export default {
  name: "Layout",
  components: { AppHeader, AppFooter, Login },
  data() {
    return {};
  },

  computed: {
    ...mapState({
      isShowLoginModal: (state) =>
        state.showLoginModal.isShowStatus.isShowLoginModal,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
