// 导入API接口函数
import { HomeGoodList } from "@/api";

// 首页模块
export default {
  namespaced: true, // 命名空间
  state: {
    searchList: [],
  },

  mutations: {
    // 修改搜索结果
    SET_SEARCH_LIST(state, payload) {
      state.searchList = payload;
    },
  },

  actions: {
    // 获取搜索结果
    async HomeGoodList({ commit }, query) {
      const res = await HomeGoodList(query);
      console.log(res, "获取搜索结果");
      commit("SET_SEARCH_LIST", res);
    },
  },

  getters: {
    // 商品列表
    searchList: (state) => state.searchList.data,
    // 总数量
    total: (state) => state.searchList.total,
  },
};
