import Vue from 'vue'

// 导入组件 并注册组件
import Dialog from './Dialog'
import FixIcon from './FixIcon'
import logisticsDialog from './logisticsDialog'
import Pagination from './Pagination'
import TypeNav from './TypeNav'

// 全局注册导航分类组件
Vue.component('TypeNav', TypeNav)
Vue.component('Pagination', Pagination)
Vue.component('Dialog', Dialog)
Vue.component('logisticsDialog', logisticsDialog)
Vue.component('FixIcon', FixIcon)

// 全局注册事件处理中心
Vue.prototype.$bus = new Vue()