<template>
  <div class="Login">
    <div class="login_container">
      <div class="mask" @click="chanModal"></div>
      <div class="login_box">
        <img
          class="del_icon"
          src="@/assets/images/del_icon.png"
          alt=""
          @click="chanModal"
        />
        <ul v-show="isShowRegister" class="title">
          <li :class="ShowForm == 1 ? 'active' : ''" @click="Show(1)">登录</li>
          <li :class="ShowForm == 2 ? 'active' : ''" @click="Show(2)">注册</li>
        </ul>

        <ul v-show="!isShowRegister" class="title" style="color: #333">
          <li>重置密码</li>
        </ul>

        <div class="form">
          <div v-show="isShowRegister">
            <!-- 密码、验证码登录 -->
            <div class="login_option" v-show="ShowForm == 1">
              <input
                class="phone_number"
                type="text"
                placeholder="手机号"
                v-model="phoneNumber"
                maxlength="11"
                autocomplete="off"
              />

              <div v-if="isShowCode" class="input_group">
                <input
                  class=""
                  :type="isShowIcon ? 'password' : 'text'"
                  placeholder="密码"
                  v-model="password"
                  minlength="6"
                  autocomplete="new-password"
                />
                <div class="icon_type" @click="changePassword">
                  <img
                    v-if="isShowIcon"
                    src="@/assets/images/close_icon.png"
                    alt=""
                  />
                  <img v-else src="@/assets/images/open_icon.png" alt="" />
                </div>
              </div>

              <div v-else class="input_group">
                <input
                  class=""
                  type="text"
                  placeholder="验证码"
                  v-model="SMScode"
                />
                <div class="get_code">
                  <span v-show="!isShowCount" @click="getSMScode(1)"
                    >获取验证码</span
                  >
                  <span v-show="isShowCount">{{ count }}s</span>
                </div>
              </div>

              <div class="login_type">
                <div class="login_code" @click="codeLogin">
                  {{ isShowCode ? "验证码登录" : "密码登录" }}
                </div>
                <div
                  class="forgot_password"
                  v-show="isShowCode"
                  @click="forgotPassword"
                >
                  忘记密码
                </div>
              </div>
              <div class="auth_clause">
                <input
                  type="checkbox"
                  name="chk_list"
                  :checked="checked"
                  @change="checkbox()"
                />
                <div class="agreement">
                  已阅读并同意
                  <router-link
                    :to="{
                      path: '/Agreement',
                      query: { configList: configList.registration_agreement },
                    }"
                    target="_blank"
                  >
                    <span>《用户服务协议》</span>
                  </router-link>
                </div>
              </div>
              <div class="btn" @click="submitLogin">登录</div>
            </div>

            <div class="register" v-show="ShowForm == 2">
              <input
                class="phone_number"
                type="text"
                placeholder="手机号"
                v-model="phoneNumber"
                maxlength="11"
              />

              <div class="input_group">
                <input
                  class=""
                  type="text"
                  placeholder="验证码"
                  v-model="SMScode"
                />
                <div class="get_code">
                  <span v-show="!isShowCount" @click="getSMScode(2)"
                    >获取验证码</span
                  >
                  <span v-show="isShowCount">{{ count }}s</span>
                </div>
              </div>

              <div class="input_group" style="margin: 26px 0">
                <input
                  class=""
                  :type="isShowIcon ? 'password' : 'text'"
                  placeholder="输入新密码"
                  v-model="password"
                  minlength="6"
                />
                <div class="icon_type" @click="changePassword">
                  <img
                    v-if="isShowIcon"
                    src="@/assets/images/close_icon.png"
                    alt=""
                  />
                  <img v-else src="@/assets/images/open_icon.png" alt="" />
                </div>
              </div>

              <div class="input_group">
                <input
                  class=""
                  :type="isShowIconAgain ? 'password' : 'text'"
                  placeholder="再次输入新密码"
                  v-model="newPassword"
                  minlength="6"
                />
                <div class="icon_type" @click="changePasswordAgain">
                  <img
                    v-if="isShowIconAgain"
                    src="@/assets/images/close_icon.png"
                    alt=""
                  />
                  <img v-else src="@/assets/images/open_icon.png" alt="" />
                </div>
              </div>
              <div class="auth_clause">
                <input
                  type="checkbox"
                  name="chk_list"
                  :checked="checked"
                  @change="checkbox()"
                />
                <div class="agreement">
                  已阅读并同意
                  <router-link
                    :to="{
                      path: '/Agreement',
                      query: { configList: configList.registration_agreement },
                    }"
                    target="_blank"
                  >
                    <span>《用户服务协议》</span>
                  </router-link>
                </div>
              </div>
              <div class="btn" @click="registerLogin">注册</div>
            </div>
          </div>

          <div v-show="!isShowRegister" class="register">
            <input
              class="phone_number"
              type="text"
              placeholder="手机号"
              v-model="phoneNumber"
              maxlength="11"
            />
            <div class="input_group">
              <input
                class=""
                type="text"
                placeholder="验证码"
                v-model="SMScode"
              />
              <div class="get_code">
                <span v-show="!isShowCount" @click="getSMScode(3)"
                  >获取验证码</span
                >
                <span v-show="isShowCount">{{ count }}s</span>
              </div>
            </div>
            <div class="input_group" style="margin: 26px 0">
              <input
                class=""
                :type="isShowIcon ? 'password' : 'text'"
                placeholder="输入新密码"
                v-model="password"
                minlength="6"
              />
              <div class="icon_type" @click="changePassword">
                <img
                  v-if="isShowIcon"
                  src="@/assets/images/close_icon.png"
                  alt=""
                />
                <img v-else src="@/assets/images/open_icon.png" alt="" />
              </div>
            </div>
            <div class="input_group">
              <input
                class=""
                :type="isShowIconAgain ? 'password' : 'text'"
                placeholder="再次输入新密码"
                v-model="newPassword"
                minlength="6"
              />
              <div class="icon_type" @click="changePasswordAgain">
                <img
                  v-if="isShowIconAgain"
                  src="@/assets/images/close_icon.png"
                  alt=""
                />
                <img v-else src="@/assets/images/open_icon.png" alt="" />
              </div>
            </div>
            <div class="login_type">
              <div></div>
              <div class="login_code" @click="forgotPassword">登录/注册</div>
            </div>
            <div class="btn" @click="resetPsw">确定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ResetpwdLoginAPI, SendSMSAPI } from "@/api";
import { mapMutations, mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      ShowForm: this.isShowForm || 1, // 登录与注册
      isShowIcon: true, // 密码的显示隐藏
      isShowIconAgain: true, // 密码的显示隐藏
      isShowCode: true, // 验证码登录的显示隐藏
      isShowRegister: true, // 重置密码的显示隐藏

      isShowCount: false, // 控制显示和隐藏倒计时数字的变量

      phoneNumber: "", // 用户输入的手机号
      password: "", // 用户输入的密码
      newPassword: "", // 用户输入的密码
      SMScode: "", // 用户输入的验证码

      count: 60, // 倒计时的数字
      maxCount: 60,
      timer: null, // timer表示倒计时的定时器
      checked: false,
    };
  },

  created() {
    // if (this.isShowForm == "true") {
    //   this.forgotPassword();
    // }
  },

  computed: {
    ...mapState({
      isShowForm: (state) => state.showLoginModal.isShowStatus.isShowForm,
    }),
    ...mapState("header", ["configList"]),
  },

  watch: {
    isShowForm(val) {
      this.ShowForm = val;
    },
  },

  methods: {
    ...mapMutations({
      chanIsShowLoginModal: "showLoginModal/chanIsShowLoginModal",
    }),

    chanModal() {
      this.chanIsShowLoginModal({ isShowLoginModal: false, isShowForm: 1 });
      this.isShowCode = true;
      this.isShowRegister = true;
      this.phoneNumber = "";
      this.password = ""; // 用户输入的密码
      this.newPassword = ""; // 用户输入的密码
      this.SMScode = ""; // 用户输入的验证码
    },

    Show(num) {
      this.ShowForm = num;
      this.phoneNumber = "";
      this.password = ""; // 用户输入的密码
      this.newPassword = ""; // 用户输入的密码
      this.SMScode = ""; // 用户输入的验证码
    },

    changePassword() {
      this.isShowIcon = !this.isShowIcon;
    },

    changePasswordAgain() {
      this.isShowIconAgain = !this.isShowIconAgain;
    },

    codeLogin() {
      this.isShowCode = !this.isShowCode;
    },

    forgotPassword() {
      this.codeLogin();
      this.isShowRegister = !this.isShowRegister;
    },

    checkbox() {
      this.checked = !this.checked;
      console.log(this.checked);
    },

    // 验证码倒计时
    countDown() {
      this.isShowCount = true;
      // 倒计时的方法
      this.timer = setInterval(() => {
        this.count--;
        // 如果this.count为0了,就不能再减了,就关闭/清除定时器
        if (this.count == 0) {
          clearInterval(this.timer);
          this.count = this.maxCount;
          this.isShowCount = false;
        }
      }, 1000);
    },

    // 手机号码的验证 (11位数字,考虑运营商)
    verifyFn() {
      let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      // if(!正则对象.test(用户输入的信息)){
      if (!this.phoneNumber) {
        this.$message.warning("请输入手机号!");
        return;
      } else if (!reg.test(this.phoneNumber)) {
        this.$message.warning("请输入正确的手机号!");
        return;
      }
      return true;
    },

    // 点击获取登录验证码按钮,执行这里的代码
    async getSMScode(num) {
      // 手机号的验证
      if (!this.verifyFn()) return;

      let res = await SendSMSAPI({
        mobile: this.phoneNumber,
        event: num == 1 ? "mobilelogin" : num == 2 ? "register" : "resetpwd",
      });
      console.log(res);

      if (res.code == 200) {
        this.$message.success(res.msg);
        // 倒计时: 显示"获取验证码"或者倒计时  倒数
        this.countDown();
      }
    },

    // 登录按钮
    async submitLogin() {
      // 手机号的验证
      if (!this.verifyFn()) return;

      // 密码登录
      if (this.isShowCode) {
        if (!this.password.trim()) {
          this.$message.warning("请输入密码");
          return false;
        }

        if (!this.checked) {
          this.$message.warning("请先阅读用户服务协议");
          return false;
        }
        // 2. 发起请求
        try {
          await this.$store.dispatch("login/pwdLogin", {
            account: this.phoneNumber,
            password: this.password,
          });
          this.$message.success("登录成功");
          // 3. 关闭登录窗口
          this.chanIsShowLoginModal(false);
          // 刷新页面
          window.location.reload();

          // const path = this.$route.query.redirect;
          // this.$router.push(path || "/");
        } catch (error) {
          console.log("登录失败");
          // this.$message.error("登录失败");
          // 3. 登录失败 清空输入框
          this.phoneNumber = "";
          this.password = "";
        }

        // let res = await passwordLogin({
        //   account: this.phoneNumber,
        //   password: this.password
        // });
        // console.log(res);
        // alert(res.msg)
        // 2 关闭登录窗口
        // this.chanIsShowLoginModal(false);
        // 3 保存token值和数据
        // localStorage.setItem("token", res.data.userinfo.token)
        // localStorage.setItem("userinfo", res.data.userinfo)
      } else {
        // 验证码登录
        if (!this.SMScode.trim()) {
          this.$message.warning("请输入验证码");
          return false;
        }
        if (!this.checked) {
          this.$message.warning("请先阅读用户服务协议");
          return false;
        }
        try {
          await this.$store.dispatch("login/codeLogin", {
            mobile: this.phoneNumber,
            captcha: this.SMScode,
          });
          this.$message.success("登录成功");
          // 3. 关闭登录窗口
          this.chanIsShowLoginModal(false);
          // 刷新页面
          window.location.reload();
        } catch (error) {
          console.log("登录失败");
          // this.$message.error("登录失败");
          // 3. 登录失败 清空输入框
          this.phoneNumber = "";
          this.SMScode = "";
        }

        // let res = await CodeLoginAPI({
        //   mobile: this.phoneNumber,
        //   captcha: this.SMScode
        // });
        // console.log(res);
        // alert(res.msg)
        // 2 关闭登录窗口
        // this.chanIsShowLoginModal(false);
        // 3 保存token值
        // localStorage.setItem("token", res.data.userinfo.token)
      }
    },

    // 确认新密码
    confirm() {
      if (!this.newPassword.trim()) {
        this.$message.warning("请输入新密码");
        return false;
      } else if (this.newPassword != this.password) {
        this.$message.warning("密码不一致");
        return;
      }

      return true;
    },

    // 注册按钮
    async registerLogin() {
      // 手机号的验证
      if (!this.verifyFn()) return;
      if (!this.SMScode.trim()) {
        this.$message.warning("请输入验证码");
        return false;
      }
      if (!this.password.trim()) {
        this.$message.warning("请输入密码");
        return false;
      }
      if (!this.confirm()) return;
      if (!this.checked) {
        this.$message.warning("请先阅读用户服务协议");
        return false;
      }
      try {
        await this.$store.dispatch("login/register", {
          username: this.phoneNumber,
          password: this.password,
          mobile: this.phoneNumber,
          code: this.SMScode,
        });
        this.$message.success("注册成功");
        // 刷新页面
        window.location.reload();
      } catch (error) {
        this.$message.error("注册失败");
      }

      // let res = await RegisterLoginAPI({
      //   username: this.phoneNumber,
      //   password: this.password,
      //   mobile: this.phoneNumber,
      //   code: this.SMScode,
      // });
    },

    // 重置密码
    async resetPsw() {
      // 手机号的验证
      if (!this.verifyFn()) return;

      if (!this.SMScode.trim()) {
        this.$message.warning("请输入验证码");
        return false;
      }
      if (!this.password.trim()) {
        this.$message.warning("请输入密码");
        return false;
      }
      if (!this.confirm()) return;

      try {
        let res = await ResetpwdLoginAPI({
          mobile: this.phoneNumber,
          newpassword: this.password,
          captcha: this.SMScode,
        });
        console.log(res);
        this.$message.success("重置密码成功");
        // 刷新页面
        // window.location.reload();
        this.isShowRegister = true;
        this.ShowForm = 1;
        this.captcha = "";
      } catch (error) {
        this.$message.error("重置密码失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  background: #f1f3f5;
}

.login_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .login_box {
    width: 500px;
    background: #ffffff;
    padding: 50px 70px 0;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .del_icon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .title {
      display: flex;
      font-size: 22px;
      font-weight: 400;
      color: #999999;

      li {
        cursor: pointer;
        margin-right: 18px;
      }

      .active {
        color: #00a4a0;
        font-weight: bold;

        border-bottom: 4px solid #00a4a0;
        padding-bottom: 5px;
      }
    }

    .form {
      width: 360px;
      margin-top: 50px;

      .login_option,
      .register {
        font-size: 20px;

        .phone_number {
          width: 100%;
          height: 50px;
          border: none;
          border-radius: 8px;
          background: #f9f9fb;
          text-indent: 16px;
          margin-bottom: 26px;
        }

        .input_group {
          height: 50px;
          display: flex;
          justify-content: space-between;
          background: #f9f9fb;
          border-radius: 8px;

          input {
            flex: 1;
            border: none;
            text-indent: 16px;
            background: #f9f9fb;
          }

          .icon_type {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 20px;
              height: 12px;
            }
          }

          .get_code {
            font-size: 14px;
            font-weight: 400;
            color: #00a4a0;
            cursor: pointer;
            text-align: center;
            line-height: 50px;
            padding: 0 17px;
          }
        }

        .login_type {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 400;
          color: #00a4a0;
          margin: 20px 10px 0;

          .login_code,
          .forgot_password {
            cursor: pointer;
          }
        }

        .btn {
          height: 50px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          background: rgba(0, 164, 160, 1);
          border-radius: 8px;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
          margin: 40px 0 90px;
        }
      }
    }
  }
}

.auth_clause {
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    position: relative;
    cursor: pointer;
  }

  input[type="checkbox"]::after {
    position: absolute;
    top: 0;
    color: #000;
    width: 16px;
    height: 16px;
    display: inline-block;
    visibility: visible;
    padding-left: 0px;
    text-align: center;
    content: " ";
    border-radius: 3px;
  }

  input[type="checkbox"]:checked::after {
    content: "✓";
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    background-color: #00a4a0;
  }

  .agreement {
    font-size: 14px;
    margin-left: 10px;

    span {
      color: #00a4a0;
      cursor: pointer;
    }
  }
}
</style>
