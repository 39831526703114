import Vue from 'vue'
import Vuex from 'vuex'

import detail from './modules/detail'
import header from './modules/header'
import home from './modules/home'
import login from './modules/login'
import search from './modules/search'

import showLoginModal from './showLoginModal'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    token: state => state.login.token,
    username: state => state.login.userInfo.username,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    home,
    search,
    detail,
    login,
    showLoginModal,
    header
  }
})
